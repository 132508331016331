.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #16293c;
  min-height: 100vh;
  color: #eaf9ff;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  text-align: center;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}
