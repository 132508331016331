.title {
    font-family: Assistant;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 85vw;
}

.title > * {
    margin-top: 2em;
}

.title > h2 {
    font-style: italic;
    margin-bottom: 2em;
    font-weight: 500;
    line-height: 1.29;
}


.title > h1 {
    font-size: 3.75em;
}
