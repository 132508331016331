.Text {
    margin: 2em 0em;
    text-align: justify;
    font-size: 1.5em;
    line-height: 140%;
    font-weight: 200;
}

.Text > b {
    font-weight: 500;
}

.highlight {
    margin-top: 1em;
    font-size: 1.6em;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
}