@media (max-width: 860px) {
    .Hero-mobile {
        display: block;
    }
    
    .Hero {
        display: none;
    }
}

@media (min-width: 861px) {
    .Hero-mobile {
        display: none;
    }

    .Hero {
        display: block;
    }
}
  
.Hero {
    pointer-events: none;
    width: 100%;
}

.Hero-mobile {
    pointer-events: none;
    width: 100vw;
}
  